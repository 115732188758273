import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router'; 
import {PreloadingStrategy, Route, Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import { AuthenticatedGuard } from './authentication/authentication.guard'; 
import { HeaderLayoutComponent } from './shared/headers/header-layout/header-layout.component';
import { HeaderlessLayoutComponent } from './shared/headers/headerless-layout/headerless-layout.component';

@Injectable({
  providedIn: 'root'
})
export class CustomPreloader implements PreloadingStrategy {

  constructor(private router: Router) {
  }

  preload(route: Route, load: Function): Observable<any> {
    const isRouteToPreload = route.data && route.data['preload'];
    return isRouteToPreload && !this.isBlackListedRoute(route) ? load() : of(null);
  }

  // we don't want to preload anything on the blacklisted routes
  isBlackListedRoute(route: Route): boolean {
    if (!route.data || !route.data['blacklist']) {
      return false;
    }
    const url = this.router.routerState.snapshot.url;
    const blackList = route.data['blacklist'];
    const routeIsInBlackList = blackList.find((value: string) => url.includes(value));

    return routeIsInBlackList !== undefined;
  }
}
const routes: Routes = [
  //page with header
  {
    path: '',
    component: HeaderLayoutComponent,
    children: [  
      //home
      {
        path: '',
        loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
        data: { preload: true, header: true}
      },
      //As designed group
      {
        path: 'arborescence',
        loadChildren: () => import('./modules/domain-as-designed/arborescence/arborescence.module').then(m => m.ArborescenceModule),
        data: { preload: true }
      },
      //As Planned / As built group
      {
        path: 'as-planned',
        loadChildren: () => import('./modules/domain-as-planned-as-built/as-planned/as-planned.module').then(m => m.ArborescenceModule),
        data: { preload: true }
      },
      {
        path: 'status-configuration',
        loadChildren: () => import('./modules/domain-as-planned-as-built/status-configuration/status-configuration.module').then(m => m.SetupConfigurationModule),
        data: { preload: true }
      },
      //Change group
      {
        path: 'ccb',
        loadChildren: () => import('./modules/domain-change/ccb/ccb.module').then(m => m.CCBModule),
        data: { preload: true }
      },
      {
        path: 'arborescence-pr',
        loadChildren: () => import('./modules/domain-change/arborescence-PR/arborescence-PR.module').then(m => m.ArborescencePRModule),
        data: { preload: true }
      },
      {
        path: 'evolution-history',
        loadChildren: () => import('./modules/domain-change/evolution-history/evolution-history.module').then(m => m.EvolutionHistoryModule),
        data: { preload: true }
      },
      //Obsolescense group
      {
        path: 'arborescence-quantity',
        loadChildren: () => import('./modules/domain-obsolescense/arborescence-quantity/arborescence-quantity.module').then(m => m.ArborescenceQuantityModule),
        data: { preload: true }
      },
      {
        path: 'article-synthesis',
        loadChildren: () => import('./modules/domain-obsolescense/article-synthesis/article-synthesis.module').then(m => m.ArticleSynthesisModule),
        data: { preload: true }
      },
      {
        path: 'mlwu',
        loadChildren: () => import('./modules/domain-obsolescense/mlwu/mlwu.module').then(m => m.MlwuModule),
        data: { preload: true }
      },
      //dashboard
      {
        path: 'reports-dashboard',
        loadChildren: () => import('./modules/reports-dashboard/reports-dashboard.module').then(m => m.ReportsDashboardModule),
        data: { preload: true}
      },
    ],
    canActivate: [AuthenticatedGuard],
    canActivateChild: [AuthenticatedGuard]
  },

  // page with no header
  { 
    path: '',
    component: HeaderlessLayoutComponent,
    children: [
      {
        path: 'login',
        loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule),
        data: { preload: false, header: false }
      },
      {
        path: 'callback',
        loadChildren: () => import('./modules/callback/callback.module').then(m => m.CallbackModule),
        data: { preload: false, header: false }
      }
    ]
  }, 
  {path: '**', redirectTo: '/'},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: CustomPreloader
    })],

  exports: [RouterModule]
})
export class MasterRoutingModule { }
