<qtm-header class="custom-header-shadow">
    <qtm-header-brand class="mx-s">
        <a [routerLink]="'/'"><img src="https://upload.wikimedia.org/wikipedia/commons/4/43/Thales.svg" alt="thales logo"></a>
        <img src="'../../../../../assets/pictures/logo ARREE.png" class="logo"/>
        <qtm-typography classes="font-roboto text-bluegrey-500 hidden medium:block pl-l" fontSize="8">PALMA DMS</qtm-typography>
    </qtm-header-brand>
    
    <qtm-header-divider classes="ml-xl hidden medium:block"></qtm-header-divider>
    <qtm-header-menu classes="overflow-x-clip">
        <qtm-header-menu-left classes="hidden small:flex overflow-x-auto">
          <qtm-tabs divider="false" fullHeight fullWidth scrollHorizontally>
           <qtm-tab active classes="flex-shrink-0" (click)="clickReportsTab()"> Reports/Extractions </qtm-tab>
           <qtm-tab classes="flex-shrink-0" (click)="clickMesRapportsServeur()"> Mes Rapports Serveurs </qtm-tab>
          </qtm-tabs>
        </qtm-header-menu-left>
      </qtm-header-menu>
</qtm-header>


<mat-drawer-container classes="drawer fixed overflow-y-auto" [hasBackdrop]="true">
    <mat-drawer-content>
        <router-outlet></router-outlet>
    </mat-drawer-content>
</mat-drawer-container>